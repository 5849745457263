const os = require("os");
const hostname = os.hostname();

export default function PISReducer(
  state = {
    // activeServer: (hostname === 'localhost' ? "http://localhost:60263/api/" : "https://c2c.permataindonesia.com/apis/"),
    activeServer: (
        hostname === 'localhost' ? "http://localhost:5002/api/" : "https://" + hostname + "/api/"
        ),
    profilePicture: []
  }, action) {
  switch (action.type) {
    case "setProfilePicture":
      return {
        ...state,
        profilePicture: action.data,
      };
    default:
      return state;
  }
}