import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

import {
  Avatar,
  Box,
  Badge,
  Menu,
  Button,
  List,
  ListItem,
  Dialog,
  Divider,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Input
} from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import avatar4 from '../../assets/images/avatars/avatar4.jpg';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  large: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    fontSize: "4rem"
  },
}));

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
})(Badge);

export default function HeaderUserbox() {
  const axios = require('axios')
  const dispatch = useDispatch();
  const activeServer = useSelector((s) => s.PIS.activeServer)
  const profilePicture = useSelector((s) => s.PIS.profilePicture)
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
    newPassword: '',
    showNewPassword: false,
    reNewPassword: '',
    showReNewPassword: false,
  });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cmdSignOut = () => {
    localStorage.clear();
    history.push('/')
  }

  const toggle1 = () => setModal1(!modal1);
  const toggle2 = () => setModal2(!modal2);

  const handleChange = (prop) => (event) => {
    if (event.target.value.length <= 6) { setValues({ ...values, [prop]: event.target.value }) }
  };

  const handleClickShowPassword = (prop) => (event) => {
    setValues({ ...values, [prop]: !values[prop] });
  };

  // const handleClickShowPassword = () => {
  //   setValues({ ...values, showPassword: !values.showPassword });
  // };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  var avatar = activeServer.replace('api/', '') + 'avatars/' + localStorage.getItem('appID') + '.png'

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  const cmdChangePWD = () => {
    if (values.newPassword.length !== 6 || values.reNewPassword.length !== 6) {
      alert('PIN Baru harus 6 digit')
      return;
    } else if (values.newPassword !== values.reNewPassword) {
      alert('PIN Baru tidak sesuai')
      return;
    }

    axios.post(activeServer + 'pin/change', { appID: localStorage.getItem('appID'), old: values.password, new: values.newPassword })
      .then((resp) => {
        if (resp.data.status === 'success') {
          alert('PIN berhasil di rubah.')
          setModal1(false)
        } else if (resp.data.status === 'not_match') {
          alert('PIN lama tidak valid.')
        } else {
          alert('Terjadi kesalahan pada server.')
        }
      })
  }

  return (
    <Fragment>
      <Button
        color="inherit"
        onClick={handleClick}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <Box>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            variant="dot">
            <Avatar sizes="44" alt={localStorage.getItem('name')} src={profilePicture} />
          </StyledBadge>
        </Box>
        <div className="d-none d-xl-block pl-3">
          <div className="font-weight-bold pt-2 line-height-1">
            {localStorage.getItem('name')}
          </div>
          <span className="text-white-50">{localStorage.getItem('role') === 'TK' ? '' : localStorage.getItem('role') + ' - ' + localStorage.getItem('region')}</span>
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                variant="dot">
                <Avatar sizes="44" alt={localStorage.getItem('name')} src={profilePicture} />
              </StyledBadge>
            </Box>
            <div className="pl-3 ">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {localStorage.getItem('name')}
              </div>
              <span className="text-black-50 text-center">
                {localStorage.getItem('role') === 'TK' ? '' : localStorage.getItem('role') + ' - ' + localStorage.getItem('region')}
              </span>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem button onClick={toggle2}>Change Picture</ListItem>
            <ListItem button onClick={toggle1}>Change PIN</ListItem>
            <ListItem button onClick={cmdSignOut}>Sign Out</ListItem>
            {/* <ListItem button>Active tasks</ListItem>
            <Divider className="w-100" />
            <ListItem className="p-0">
              <div className="grid-menu grid-menu-2col w-100">
                <div className="py-3">
                  <div className="d-flex justify-content-center">
                    <div className="d-flex align-items-center">
                      <div>
                        <FontAwesomeIcon
                          icon={['far', 'chart-bar']}
                          className="font-size-xxl text-info"
                        />
                      </div>
                      <div className="pl-3 line-height-sm">
                        <b className="font-size-lg">$9,693</b>
                        <span className="text-black-50 d-block">revenue</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ListItem>
            <Divider className="w-100" />
            <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
              <Tooltip arrow title="Facebook">
                <Button color="default" className="text-facebook">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fab', 'facebook']} />
                  </span>
                </Button>
              </Tooltip>
              <Tooltip arrow title="Dribbble">
                <Button color="default" className="text-dribbble mr-2 ml-2">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fab', 'dribbble']} />
                  </span>
                </Button>
              </Tooltip>
              <Tooltip arrow title="Twitter">
                <Button color="default" className="text-twitter">
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                  </span>
                </Button>
              </Tooltip>
            </ListItem> */}
          </List>
        </div>
      </Menu>

      <Dialog scroll="body" maxWidth="lg" open={modal1} onClose={toggle1}>

        <div className="p-4">

          <div className="w-100 p-3">
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="standard-adornment-password">Current PIN</InputLabel>
              <Input
                id="standard-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('showPassword')}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className="w-100 p-3">
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="new-password">New PIN</InputLabel>
              <Input
                id="new-password"
                type={values.showNewPassword ? 'text' : 'password'}
                value={values.newPassword}
                onChange={handleChange('newPassword')}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('showNewPassword')}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className="w-100 p-3">
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel htmlFor="renew-password">Re-input New PIN</InputLabel>
              <Input
                id="renew-password"
                type={values.showReNewPassword ? 'text' : 'password'}
                value={values.reNewPassword}
                onChange={handleChange('reNewPassword')}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword('showReNewPassword')}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

          </div>

        </div>
        <Divider className="m-3" />
        <Button variant="contained" color="primary" className="m-3" onClick={cmdChangePWD}>Change PIN</Button>
      </Dialog>

      <Dialog open={modal2} onClose={toggle2}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            {/* <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
              <FontAwesomeIcon
                icon={['far', 'lightbulb']}
                className="d-flex align-self-center display-3"
              />
            </div> */}
            <Avatar sizes="80" alt={localStorage.getItem('name')} src={avatar} className={classes.large} />
          </div>
          <h4 className="font-weight-bold mt-4">{localStorage.getItem('name')}</h4>
          <p className="mb-0 font-size-lg text-muted">
            {localStorage.getItem('role') + " - " + localStorage.getItem('region')}
          </p>
          <div className="pt-4">
            <input type="file" id="file1" style="display:none" />
            <Button
              onClick={toggle1}
              color="secondary"
              variant="contained"
              className="mx-1"
            >
              <span className="btn-wrapper--label">Change</span>
            </Button>
            <Button
              onClick={toggle1}
              color="primary"
              variant="contained"
              className="mx-1">
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
}
