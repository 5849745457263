import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import { PulseLoader } from 'react-spinners';

import MuiTheme from './theme';

// Layout Blueprints

import {
  LeftSidebar,
  CollapsedSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints';


const LandingPage = lazy(() => import('./landing-page'));
// const FormCV = lazy(() => import('./recruitment/form-cv'));
const Apply = lazy(() => import('./apply'));
const Step1 = lazy(() => import('./apply/step1'));
const Step2 = lazy(() => import('./apply/step2'));
const Step3 = lazy(() => import('./apply/step3'));
const Step4 = lazy(() => import('./apply/step4'));
const Step5 = lazy(() => import('./apply/step5'));
const Step6 = lazy(() => import('./apply/step6'));
const Finish = lazy(() => import('./apply/finish'));
const Candidate = lazy(() => import('./candidate'));
const DocUpload = lazy(() => import('./candidate/doc-upload'));
const DocDownload = lazy(() => import('./candidate/doc-download'));
const ApprovalContract = lazy(() => import('./candidate/approval'));
const Empty = lazy(() => import('./admin/empty'));
const Thanks = lazy(() => import('./admin/thanks'));
const Logout = lazy(() => import('./admin/logoutRoute'));


const Admin = lazy(() => import('./admin'));
const SignIn = lazy(() => import('./admin/signin'));
const ContractList = lazy(() => import('./admin/contract'));
const ContractPDF = lazy(() => import('./admin/contract/pdf'));
const Applicant = lazy(() => import('./admin/applicant'));
const Employee = lazy(() => import('./admin/employee'));
const PendingContract = lazy(() => import('./admin/contract-pending'));
const CompletedContract = lazy(() => import('./admin/contract-completed'));
const EmployeeApprovalContract = lazy(() => import('./admin/employee/employee-approval'))
const EmployeeApprovalUpload = lazy(() => import('./admin/employee/employee-upload'))
const User = lazy(() => import('./admin/user'));
const Bpjs = lazy(() => import('./admin/bpjs'));
const Tax = lazy(() => import('./admin/tax'));

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <PulseLoader color={'#B00020'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            Loading data...
            {/* <span className="font-size-lg d-block text-dark">
              This live preview instance can be slower than a real production
              build!
            </span> */}
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            {/* <Redirect exact from="/" to="/landing-page" /> */}
            <Route exact path={["/"]}>
              <PresentationLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <Route path="/" exact component={LandingPage} />
                  </motion.div>
                </Switch>
              </PresentationLayout>
            </Route>
            <Route exact path={['/lowongan-kerja', '/apply', '/apply/00000', '/sign-in', '/apply/step1',
              '/apply/step2', '/apply/step3', '/apply/step4', '/apply/step5',
              '/apply/step6', '/apply/finish', '/contract-approval/:id', '/contract-download/:id', '/employee-contract-approval/:id','/employee-contract-download/:id','/employee-contract-upload/:id','/employee-contract-upload',"/empty",'/thanks','/logout']}>
              <PresentationLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    {/* <Route exact path="/" component={LandingPage} /> */}
                    <Route exact path="/sign-in" component={SignIn} />
                    <Route exact path="/apply" component={Apply} />
                    <Route exact path="/apply/00000" component={Apply} />
                    <Route exact path="/apply/step1" component={Step1} />
                    <Route exact path="/apply/step2" component={Step2} />
                    <Route exact path="/apply/step3" component={Step3} />
                    <Route exact path="/apply/step4" component={Step4} />
                    <Route exact path="/apply/step5" component={Step5} />
                    <Route exact path="/apply/step6" component={Step6} />
                    <Route exact path="/apply/finish" component={Finish} />
                    <Route exact path="/contract-approval/:id" component={ApprovalContract} />
                    <Route exact path="/contract-download/:id" component={ApprovalContract} />
                    <Route exact path="/employee-contract-approval/:id" component={EmployeeApprovalContract} />
                    <Route exact path="/employee-contract-download/:id" component={EmployeeApprovalContract} />
                    <Route exact path="/employee-contract-upload/:id" component={EmployeeApprovalContract} />
                    <Route exact path="/employee-contract-upload" component={EmployeeApprovalUpload} />
                    <Route exact path="/empty" component={Empty} />
                    <Route exact path="/thanks" component={Thanks} />
                    <Route exact path="/logout" component={Logout} />
                  </motion.div>
                </Switch>
              </PresentationLayout>
            </Route>

            <Route
              path={['/admin', '/contract-pdf', '/applicant', '/contract-employee', '/contract-pending','/contract-completed', '/user', '/contract', '/candidate',
                '/document/upload', '/document/download', '/bpjs', '/tax']}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route exact path="/admin" component={Admin} />
                    <Route exact path="/contract-pdf" component={ContractPDF} />
                    <Route exact path="/contract" component={ContractList} />
                    <Route exact path="/applicant" component={Applicant} />
                    <Route exact path="/contract-employee" component={Employee} />
                    <Route exact path="/contract-pending" component={PendingContract} />
                    <Route exact path="/contract-completed" component={CompletedContract} />
                    <Route exact path="/user" component={User} />
                    <Route exact path="/bpjs" component={Bpjs} />
                    <Route exact path="/tax" component={Tax} />

                    <Route exact path="/candidate" component={Candidate} />
                    <Route exact path="/document/upload" component={DocUpload} />
                    <Route exact path="/document/download" component={DocDownload} />

                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>


          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
